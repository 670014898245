// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: "319a8c59-35b1-4b15-acb3-0d978a72cae3",
    authority:
      "https://capla360.b2clogin.com/capla360.onmicrosoft.com/B2C_1_signin-signup-1",
    knownAuthorities: ["capla360.b2clogin.com"],
    //redirectUri: "http://localhost:3001/auth",
    redirectUri: "https://opemap.capla360.com/auth",
    postLogoutRedirectUri: "/",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
