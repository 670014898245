import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import { Typography, Paper, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

import PageLayout from "Components/PageLayout";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    width: theme.spacing(16),
    height: theme.spacing(16),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Home() {
  const classes = useStyles();
  const { accounts, instance } = useMsal();

  useEffect(() => {
    if (accounts.length === 0) {
      instance.loginRedirect();
    }
  }, [accounts]);

  return (
    <PageLayout>
      <Paper elevation={8} className={classes.card}>
        <Link href="https://www.capla360.com">capla 360</Link>
      </Paper>
    </PageLayout>
  );
}
