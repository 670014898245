// styles
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import theme from "Styles/theme";

// routes
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes/Routes";
import { MsalProvider } from "@azure/msal-react";

function App({ pca }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={pca}>
        <Router>
          <Routes />
        </Router>
      </MsalProvider>
    </ThemeProvider>
  );
}

export default App;
