import NavBar from "./NavBar";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const PageLayout = (props) => {
  const classes = useStyles();
  return (
    <>
      <NavBar />
      <div className={classes.root}>{props.children}</div>
    </>
  );
};
export default PageLayout;
