import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";

import PageLayout from "Components/PageLayout";
export default function Test() {
  const { accounts, instance } = useMsal();

  async function logToken() {
    const request = {
      account: accounts[0],
    };
    //const token = await instance.acquireTokenSilent(request);
    console.log(request.account);
  }
  useEffect(() => {
    logToken();
  }, [accounts]);
  return (
    <PageLayout>
      <div>Page de test</div>
    </PageLayout>
  );
}
